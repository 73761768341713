<template>
  <el-container class="quiz-content" :lang="$i18n.locale">
    <el-header id="site_header">
      <a @click="$router.back()" target="_top" class="site-homelink">
        <img
          class="site-logo"
          src="@/assets/img/logo-biwas-waterline.png"
          title="www.biwas.de"
          alt="www.biwas.de"
        />
        <div class="title">
          <div id="site-title"></div>
          {{ $t('views.header.title') }}
        </div>
      </a>
      <slot name="header" />
    </el-header>
    <slot />
    <el-footer id="site_footer">
      <slot name="footer" />
      <p class="site-author">&copy; 2024 BiWAS</p>
    </el-footer>
  </el-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
  emits: [],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class QuizLayout extends Vue {}
</script>

<style lang="scss" scoped>
.el-header,
.el-footer {
  height: unset;
}

.quiz-content {
  height: 100dvh;
  background-color: #ffffff;
}

.site-homelink {
  position: relative;
}

.title {
  font-family: var(--font-family-headline);
  font-weight: var(--font-weight-bold);
  font-size: min(4vw, 2.5rem);
  text-align: right;
  color: var(--color-default);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#site-title {
  flex: 0 0 25%;
  display: block;
  position: relative;
  height: 0.1px;
  padding-bottom: 14%;
  overflow: hidden;
}
</style>
