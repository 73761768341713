import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0eaad82"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "header"
}
const _hoisted_2 = { class: "timeline" }
const _hoisted_3 = {
  key: 0,
  class: "count"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 2,
  class: "hint blink"
}
const _hoisted_6 = { class: "content" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "timeline" }
const _hoisted_9 = {
  key: 0,
  class: "correct"
}
const _hoisted_10 = {
  key: 1,
  class: "wrong"
}
const _hoisted_11 = { class: "explanation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_quiz_layout = _resolveComponent("quiz-layout")!

  return (_openBlock(), _createBlock(_component_quiz_layout, null, {
    header: _withCtx(() => [
      (_ctx.$slots.header)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "header", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!_ctx.showInfo && _ctx.question)
        ? (_openBlock(), _createBlock(_component_el_main, {
            key: 0,
            class: "question"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_el_steps, { active: _ctx.questionNo }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stateList, (item, index) => {
                      return (_openBlock(), _createBlock(_component_el_step, {
                        key: index,
                        status: _ctx.getStatus(item)
                      }, null, 8, ["status"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["active"])
              ]),
              _createElementVNode("h2", null, _toDisplayString(_ctx.question.title), 1),
              (_ctx.correctList.length > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('views.quiz.multipleAnswers')), 1))
                : _createCommentVNode("", true),
              (_ctx.question.description)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.question.description), 1))
                : _createCommentVNode("", true),
              (_ctx.showHint)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.question.hint), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _createElementVNode("button", {
                disabled: !_ctx.canConfirm,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args))),
                class: "confirm"
              }, _toDisplayString(_ctx.$t('views.quiz.confirm')), 9, _hoisted_7)
            ]),
            _: 3
          }))
        : (_ctx.question)
          ? (_openBlock(), _createBlock(_component_el_main, {
              key: 1,
              class: "info"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_el_steps, { active: _ctx.questionNo }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stateList, (item, index) => {
                        return (_openBlock(), _createBlock(_component_el_step, {
                          key: index,
                          status: _ctx.getStatus(item)
                        }, null, 8, ["status"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["active"])
                ]),
                (_ctx.isCorrect)
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_9, _toDisplayString(_ctx.$t('views.quiz.correct')), 1))
                  : (_openBlock(), _createElementBlock("h3", _hoisted_10, [
                      _createTextVNode(_toDisplayString(_ctx.$t('views.quiz.wrong')) + " ", 1),
                      _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.correctList, (item) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: item.id
                          }, _toDisplayString(item.title), 1))
                        }), 128))
                      ])
                    ])),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.question.explanation), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}