<template>
  <div
    ref="canvasContainer"
    class="canvasContainer"
    :style="{
      backgroundColor: animationConfig.backgroundColor,
      backgroundImage: animationConfig.background
        ? `url(/assets/animation/${animationConfig.background})`
        : 'none',
      '--x': `${animationProps.backgroundPositionX}px`,
      '--y': `${animationProps.backgroundPositionY}px`,
      '--sizeX': backgroundZoomX,
      '--sizeY': backgroundZoomY,
    }"
  >
    <div
      v-if="animationConfig.overlay"
      class="backgroundOverlay"
      :style="{
        width: `${containerWidth}px`,
        height: `${containerHeight}px`,
        backgroundImage: animationConfig.overlay
          ? `url(/assets/animation/${animationConfig.overlay})`
          : 'none',
        '--percent': `${overlayPercent}%`,
      }"
    ></div>
    <!--
      :background-color="animationConfig.backgroundColor"
    -->
    <Application
      v-if="isLoaded && isContainerSet && backgroundTexture"
      ref="pixi"
      :width="containerWidth"
      :height="containerHeight"
      :transparent="true"
      :backgroundAlpha="0"
      :sortableChildren="true"
    >
      <sprite
        v-if="animationConfig.backgroundSprite"
        :texture="animationSprite.textures[animationConfig.backgroundSprite]"
        :width="getObstacleWidth()"
        :height="getObstacleHeight()"
        :x="getObstacleX() + animationProps.backgroundPositionX"
        :y="getObstacleY() + animationProps.backgroundPositionY"
        :anchor-x="animationConfig.stepAnchorX"
        :anchor-y="animationConfig.stepAnchorY"
      />
      <container :sortableChildren="true">
        <transition-group v-for="stepIndex in stepCount" :key="stepIndex">
          <animated-sprite
            v-for="frameIndex in getFrameCount(stepIndex - 1)"
            :key="frameIndex"
            :textures="getObstacleAnimation(stepIndex - 1, frameIndex - 1)"
            :animation-speed="0.1"
            :width="getObstacleWidth(stepIndex - 1, frameIndex - 1, 0, true)"
            :height="getObstacleHeight(stepIndex - 1, frameIndex - 1, 0, true)"
            :x="
              getObstacleX(stepIndex - 1, frameIndex - 1) +
              animationProps.backgroundPositionX
            "
            :y="
              getObstacleY(stepIndex - 1, frameIndex - 1) +
              animationProps.backgroundPositionY
            "
            :zIndex="getObstacleZIndex(stepIndex - 1, frameIndex - 1)"
            :anchor-x="getObstacleAnchorX(stepIndex - 1)"
            :anchor-y="getObstacleAnchorY(stepIndex - 1)"
            :loop="false"
            :playing="stepIndex <= animationProps.index"
            @complete="obstacleCompleted"
          />
        </transition-group>
        <text
          v-if="
            animationProps &&
            animationProps.index > 0 &&
            animationConfig.displayText &&
            completedIndex === animationProps.index
          "
          :anchor="[-0.2, getObstacleAnchorY(animationProps.index - 1)]"
          :x="
            getObstacleX(animationProps.index - 1) +
            animationProps.backgroundPositionX
          "
          :y="
            getObstacleY(animationProps.index - 1) +
            animationProps.backgroundPositionY
          "
          :style="{
            fontFamily: 'Arial',
            fontWeight: 'bolder',
            fontSize: 42,
            fill: '#ffffff',
            stroke: '#000000',
            dropShadow: true,
          }"
          :scale="calcBackgroundZoom(animationProps.index - 1) / 4"
        >
          {{
            $t(
              `views.quiz.animations.${visualizationType}.${getStep(animationProps.index - 1).obstacleAnimation}`
            )
          }}
        </text>
        <animated-sprite
          v-for="(vehicle, index) in vehicles"
          :key="index"
          :textures="getCurrentVehicleAnimation(vehicle, index)"
          :animation-speed="0.1"
          :width="getSpriteWidth(vehicle, true)"
          :height="getSpriteHeight(vehicle, true)"
          :x="getForegroundX(index)"
          :y="getSpriteY(vehicle)"
          :zIndex="1"
          :anchor-x="1"
          :anchor-y="1"
          :loop="animationProps.foreground[index].tween?.isPlaying()"
          :playing="animationProps.foreground[index].tween?.isPlaying()"
          @loop="() => vehicleLoop(index)"
        />
      </container>
    </Application>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { VisualizationType } from '@/types/enum/VisualizationType';
import { Application } from 'vue3-pixi';
import { delay, until } from '@/utils/wait';
import * as pixiUtil from '@/utils/pixi';
import config from '@/assets/data/animation.json';
import * as PIXI from 'pixi.js';
import * as TWEEDLE from 'tweedle.js';
import { ScaleOrientation } from '@/types/enum/ScaleOrientation';
import { CameraMovement } from '@/types/enum/CameraMovement';
import { BackgroundFill } from '@/types/enum/BackgroundFill';
import { MovementAnchor } from '@/types/enum/MovementAnchor';
import { AnimationTime } from '@/types/enum/AnimationTime';
import { getCssColor } from '@/utils/colors';

interface Vehicle {
  animation: string;
  x: number;
  y: number;
  aspect: number;
  width: number;
  height: number;
  scale: number;
  vehicleScaleFactor: number;
  stickOnBackground: boolean | null;
  finalX: string;
  introAnimation: string;
}

interface ForegroundProp {
  index: number;
  completed: number;
  tween: TWEEDLE.Tween<ForegroundProp> | null;
  x: number;
  loopIndex: number;
}

interface StepProp {
  tween: TWEEDLE.Tween<StepProp> | null;
  x: number;
  y: number;
  layers: LayerProp[] | null;
}

interface LayerProp {
  tween: TWEEDLE.Tween<LayerProp> | null;
  x: number;
  y: number;
  foreground: boolean;
}

interface AnimationProp {
  index: number;
  backgroundPositionX: number;
  backgroundPositionY: number;
  backgroundZoom: number;
  tween: TWEEDLE.Tween<AnimationProp> | null;
  foreground: ForegroundProp[];
  foregroundX: number[];
  steps: StepProp[];
}

@Options({
  components: { Application },
  emits: ['update:modelValue', 'animationCompleted', 'finalCompleted'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class VisualProgress extends Vue {
  @Prop() readonly visualizationType!: VisualizationType;
  @Prop({ default: false }) readonly modelValue!: boolean;
  @Prop({ default: false }) readonly animateFinalStep!: boolean;
  @Prop({ default: 10 }) readonly questionCount!: number;
  @Prop({ default: ['ship'] }) readonly answerKey!: string[];
  readonly defaultDuration = 7000;
  containerWidth = 100;
  containerHeight = 100;
  isContainerSet = false;
  isLoaded = false;
  backgroundTexture: PIXI.Texture | null = null;
  animationSprite: PIXI.Spritesheet | null = null;
  animationProps: AnimationProp = {
    index: 0,
    backgroundPositionX: 0,
    backgroundPositionY: 0,
    backgroundZoom: 1,
    tween: null,
    foreground: [],
    foregroundX: [],
    steps: [],
  };

  get mainColor(): string {
    return getCssColor('--color-main');
  }

  get animationDuration(): number {
    return this.animationConfig.animationDuration ?? 7000;
  }

  get backgroundZoomX(): string {
    if (this.backgroundFill === BackgroundFill.width)
      return `${this.animationProps.backgroundZoom * 100}%`;
    return 'auto';
  }

  get backgroundZoomY(): string {
    if (this.backgroundFill === BackgroundFill.height)
      return `${this.animationProps.backgroundZoom * 100}%`;
    return 'auto';
  }

  calcBackgroundZoom(stepIndex: number): number {
    const zoomX = this.containerWidth / this.initEnvironmentWidth;
    const zoomY = this.containerHeight / this.initEnvironmentHeight;
    let zoom = 1;
    const step = this.getStep(stepIndex);
    const minZoomX =
      step && Object.hasOwn(step, 'minZoomX')
        ? step.minZoomX
        : this.animationConfig.minZoomX;
    const minZoomY =
      step && Object.hasOwn(step, 'minZoomY')
        ? step.minZoomY
        : this.animationConfig.minZoomY;
    if (minZoomX && zoomX > minZoomX) zoom = zoomX / minZoomX;
    if (minZoomY && zoomY > minZoomY) {
      const value = zoomY / minZoomY;
      if (value > zoom) zoom = value;
    }
    return zoom;
  }

  getScaleFactor(index: number, dynamic: boolean = false): number {
    let aspectFactor = 1;
    if (this.containerAspect > this.animationConfig.maxAspect) {
      aspectFactor = this.animationConfig.maxAspect / this.containerAspect;
    }
    const backgroundZoom = dynamic
      ? this.animationProps.backgroundZoom
      : this.calcBackgroundZoom(index);

    if (this.animationConfig.scaleFactor)
      return this.animationConfig.scaleFactor * aspectFactor * backgroundZoom;
    return 0.3 * aspectFactor * backgroundZoom;
  }

  get cameraMovement(): CameraMovement {
    return this.animationConfig.backgroundAnimation;
  }

  get backgroundFill(): BackgroundFill {
    return this.animationConfig.backgroundFill;
  }

  get movementAnchor(): MovementAnchor {
    return this.animationConfig.movementAnchor;
  }

  get animationTime(): AnimationTime {
    return this.animationConfig.animationTime;
  }

  get vehicles(): Vehicle[] {
    return this.animationConfig.vehicles;
  }

  get vehicleIndex(): number[] {
    if (this.vehicles.length === 1) return [0];
    if (this.vehicles.length > 0) {
      return this.answerKey.map((key) => {
        const index = this.vehicles.findIndex((item) => item.animation === key);
        if (index > 0) return index;
        return 0;
      });
    }
    return [];
  }

  get overlayPercent(): number {
    const percent = this.animationProps.index * (100 / this.questionCount);
    const visible = this.environmentWidth / this.containerWidth;
    const offset =
      (this.animationProps.backgroundPositionX / this.environmentWidth) * -100;
    return percent * visible - offset * visible;
  }

  getSpriteWidth(vehicle: Vehicle, dynamic: boolean = false): number {
    if (this.animationConfig.scaleOrientation === ScaleOrientation.horizontal)
      return (
        this.containerWidth *
        this.getScaleFactor(this.animationProps.index - 1, dynamic) *
        vehicle.scale *
        (vehicle.vehicleScaleFactor || 1)
      );
    return this.getSpriteHeight(vehicle, dynamic) * this.getAspect(vehicle);
  }

  getSpriteHeight(vehicle: Vehicle, dynamic: boolean = false): number {
    if (this.animationConfig.scaleOrientation === ScaleOrientation.horizontal)
      return this.getSpriteWidth(vehicle, dynamic) / this.getAspect(vehicle);
    return (
      this.containerHeight *
      this.getScaleFactor(this.animationProps.index - 1, dynamic) *
      vehicle.scale *
      (vehicle.vehicleScaleFactor || 1)
    );
  }

  getForegroundX(index: number): number {
    if (this.vehicles[index].stickOnBackground)
      return (
        this.animationProps.foregroundX[index] +
        this.animationProps.backgroundPositionX
      );
    return this.animationProps.foregroundX[index];
  }

  getSpriteX(vehicle: Vehicle): number {
    const factor = vehicle.x;
    return Math.round(this.containerHeight * factor);
  }

  getSpriteY(vehicle: Vehicle): number {
    const factor = vehicle.y;
    return Math.round(this.containerHeight * factor);
  }

  get initZoomedFactorX(): number {
    if (this.backgroundTexture) {
      return this.backgroundTexture.height / this.containerHeight;
    }
    return 1;
  }

  get initZoomedFactorY(): number {
    if (this.backgroundTexture) {
      return this.backgroundTexture.width / this.containerWidth;
    }
    return 1;
  }

  getZoomedFactorX(index: number): number {
    const backgroundZoom = this.calcBackgroundZoom(index);
    if (this.backgroundTexture) {
      return (
        this.backgroundTexture.height / this.containerHeight / backgroundZoom
      );
    }
    return backgroundZoom;
  }

  getZoomedFactorY(index: number): number {
    const backgroundZoom = this.calcBackgroundZoom(index);
    if (this.backgroundTexture) {
      return (
        this.backgroundTexture.width / this.containerWidth / backgroundZoom
      );
    }
    return backgroundZoom;
  }

  get initZoomedWidth(): number {
    if (this.backgroundTexture) {
      const zoom = this.initZoomedFactorX;
      return Math.round(this.backgroundTexture.width / zoom);
    }
    return this.containerWidth;
  }

  get initZoomedHeight(): number {
    if (this.backgroundTexture) {
      const zoom = this.initZoomedFactorY;
      return Math.round(this.backgroundTexture.height / zoom);
    }
    return this.containerHeight;
  }

  get zoomedWidth(): number {
    if (this.backgroundTexture) {
      const zoom = this.getZoomedFactorX(this.animationProps.index - 1);
      return Math.round(this.backgroundTexture.width / zoom);
    }
    return this.containerWidth;
  }

  get zoomedHeight(): number {
    if (this.backgroundTexture) {
      const zoom = this.getZoomedFactorY(this.animationProps.index - 1);
      return Math.round(this.backgroundTexture.height / zoom);
    }
    return this.containerHeight;
  }

  get initEnvironmentWidth(): number {
    if (
      this.backgroundFill === BackgroundFill.height &&
      this.cameraMovement !== CameraMovement.none
    ) {
      return this.initZoomedWidth;
    }
    return this.containerWidth;
  }

  get initEnvironmentHeight(): number {
    if (
      this.backgroundFill === BackgroundFill.width &&
      this.cameraMovement !== CameraMovement.none
    ) {
      return this.initZoomedHeight;
    }
    return this.containerHeight;
  }

  get environmentWidth(): number {
    return this.initEnvironmentWidth * this.animationProps.backgroundZoom;
  }

  get environmentHeight(): number {
    return this.initEnvironmentHeight * this.animationProps.backgroundZoom;
  }

  get containerAspect(): number {
    return this.containerWidth / this.containerHeight;
  }

  get backgroundAspect(): number {
    if (this.backgroundTexture)
      return (
        this.backgroundTexture.orig.width / this.backgroundTexture.orig.height
      );
    return this.containerAspect;
  }

  get animationConfig(): any {
    return config[this.visualizationType];
  }

  getCurrentVehicleAnimation(vehicle: Vehicle, vehicleIndex = 0): any {
    if (this.animationSprite) {
      if (
        vehicle.introAnimation &&
        this.animationProps.foreground[vehicleIndex].loopIndex <= 1
      )
        return this.animationSprite.animations[vehicle.introAnimation];
      return this.animationSprite.animations[vehicle.animation];
    }
    return null;
  }

  getCurrentVehicleSprite(vehicle: Vehicle, vehicleIndex = 0): any {
    const animation = this.getCurrentVehicleAnimation(vehicle, vehicleIndex);
    if (animation) {
      for (const sprite of animation) {
        if (sprite.orig.width > 1 || sprite.orig.height > 1) return sprite;
      }
    }
    return null;
  }

  getAspect(vehicle: Vehicle): number {
    if (vehicle.aspect) return vehicle.aspect;
    const sprite = this.getCurrentVehicleSprite(vehicle);
    if (sprite) {
      return pixiUtil.getTextureAspect(sprite);
    }
    return 1;
  }

  get stepCount(): number {
    return this.animationConfig.steps.length;
  }

  getInitBackgroundX(index: number): number {
    if (
      this.cameraMovement === CameraMovement.movePath ||
      this.cameraMovement === CameraMovement.moveY ||
      this.cameraMovement === CameraMovement.movePathY
    ) {
      const startPathX = Object.hasOwn(this.animationConfig, 'startPathX')
        ? this.animationConfig.startPathX
        : 0.5;

      const backgroundZoom = this.calcBackgroundZoom(index - 1);
      if (this.backgroundFill === BackgroundFill.height) {
        const width =
          backgroundZoom * this.containerHeight * this.backgroundAspect;
        return (this.containerWidth - width) * startPathX;
      }
      return -(backgroundZoom - 1) * this.containerWidth * startPathX;
    }
    return 0;
  }

  getInitBackgroundY(index: number): number {
    if (
      this.cameraMovement === CameraMovement.movePath ||
      this.cameraMovement === CameraMovement.moveX ||
      this.cameraMovement === CameraMovement.movePathX
    ) {
      const startPathY = Object.hasOwn(this.animationConfig, 'startPathY')
        ? this.animationConfig.startPathY
        : 0.5;
      const backgroundZoom = this.calcBackgroundZoom(index - 1);
      if (this.backgroundFill === BackgroundFill.width) {
        const height =
          (backgroundZoom * this.containerWidth) / this.backgroundAspect;
        return (this.containerHeight - height) * startPathY;
      }
      return -(backgroundZoom - 1) * this.containerHeight * startPathY;
    }
    return 0;
  }

  getStep(index: number): any {
    return this.animationConfig.steps[index];
  }

  getFrameCount(stepIndex: number): number {
    if (stepIndex >= this.stepCount) stepIndex = this.stepCount - 1;
    const step = this.getStep(stepIndex);
    if (step.layers) return step.layers.length;
    return 1;
  }

  getFrame(stepIndex: number, frameIndex: number): any {
    if (stepIndex >= this.stepCount) stepIndex = this.stepCount - 1;
    const step = this.getStep(stepIndex);
    if (step.layers) {
      if (frameIndex >= step.layers.length) frameIndex = step.layers.length - 1;
      return step.layers[frameIndex];
    }
    return step;
  }

  getObstacleAnimation(stepIndex: number, frameIndex: number): any {
    const current = this.getFrame(stepIndex, frameIndex);
    if (current.sheet)
      return current.sheet.animations[current.obstacleAnimation];
    return this.animationSprite?.animations[current.obstacleAnimation];
  }

  getObstacleSprite(stepIndex: number, frameIndex: number): any {
    const animation = this.getObstacleAnimation(stepIndex, frameIndex);
    for (const sprite of animation) {
      if (sprite.orig.width > 1 || sprite.orig.height > 1) {
        return sprite;
      }
    }
    return animation[0];
  }

  getObstacleAspect(stepIndex: number, frameIndex: number): number {
    return pixiUtil.getTextureAspect(
      this.getObstacleSprite(stepIndex, frameIndex)
    );
  }

  getObstacleWidth(
    stepIndex = 0,
    frameIndex = 0,
    animationIndex: number | null = null,
    dynamic: boolean = false
  ): number {
    if (this.animationConfig.scaleOrientation === ScaleOrientation.horizontal) {
      return (
        this.containerWidth *
        this.getObstacleScaleFactor(stepIndex, frameIndex, dynamic)
      );
    }
    if (this.animationConfig.scaleOrientation === ScaleOrientation.vertical) {
      return (
        this.getObstacleHeight(stepIndex, frameIndex, animationIndex, dynamic) *
        this.getObstacleAspect(stepIndex, frameIndex)
      );
    }
    const textureWidth =
      animationIndex !== null
        ? this.getObstacleAnimation(stepIndex, frameIndex)[animationIndex].orig
            .width
        : this.getObstacleSprite(stepIndex, frameIndex).orig.width;
    const width =
      textureWidth *
      this.getObstacleScaleFactor(stepIndex, frameIndex, dynamic);
    if (this.backgroundFill === BackgroundFill.height) {
      return width / this.getZoomedFactorX(stepIndex);
    }
    if (this.backgroundFill === BackgroundFill.width) {
      return width / this.getZoomedFactorY(stepIndex);
    }
    return width;
  }

  getObstacleHeight(
    stepIndex = 0,
    frameIndex = 0,
    animationIndex: number | null = null,
    dynamic: boolean = false
  ): number {
    if (this.animationConfig.scaleOrientation === ScaleOrientation.horizontal) {
      return (
        this.getObstacleWidth(stepIndex, frameIndex, animationIndex, dynamic) /
        this.getObstacleAspect(stepIndex, frameIndex)
      );
    }
    if (this.animationConfig.scaleOrientation === ScaleOrientation.vertical) {
      return (
        this.containerHeight *
        this.getObstacleScaleFactor(stepIndex, frameIndex, dynamic)
      );
    }
    const textureHeight =
      animationIndex !== null
        ? this.getObstacleAnimation(stepIndex, frameIndex)[animationIndex].orig
            .height
        : this.getObstacleSprite(stepIndex, frameIndex).orig.height;
    const height =
      textureHeight *
      this.getObstacleScaleFactor(stepIndex, frameIndex, dynamic);
    if (this.backgroundFill === BackgroundFill.height) {
      return height / this.getZoomedFactorX(stepIndex);
    }
    if (this.backgroundFill === BackgroundFill.width) {
      return height / this.getZoomedFactorY(stepIndex);
    }
    return height;
  }

  getObstacleZIndex(stepIndex = 0, frameIndex = 0): number {
    const current = this.getFrame(stepIndex, frameIndex);
    if (current.foreground) {
      return 2;
    }
    return 0;
  }

  getObstacleScaleFactor(
    stepIndex: number,
    frameIndex: number,
    dynamic: boolean = false
  ): number {
    const scaleFactor = this.getScaleFactor(stepIndex, dynamic);
    if (this.vehicles.length > 0) {
      const vehicleTexture = this.getCurrentVehicleSprite(this.vehicles[0]);
      const obstacleTexture = this.getObstacleSprite(stepIndex, frameIndex);
      const scale = obstacleTexture.orig.height / vehicleTexture.orig.height;
      const obstacleScaleFactor = this.getFrame(
        stepIndex,
        frameIndex
      ).obstacleScaleFactor;
      return scaleFactor * scale * (obstacleScaleFactor || 1);
    }
    return scaleFactor;
  }

  getAnimationFrame(stepIndex: number, frameIndex: number): any {
    if (stepIndex >= this.animationProps.steps.length)
      stepIndex = this.animationProps.steps.length - 1;
    const step = this.animationProps.steps[stepIndex];
    if (step.layers) {
      if (frameIndex >= step.layers.length) frameIndex = step.layers.length - 1;
      return step.layers[frameIndex];
    }
    return step;
  }

  getObstacleX(stepIndex = 0, frameIndex = 0): number {
    const x = this.getAnimationFrame(stepIndex, frameIndex).x;
    if (this.cameraMovement === CameraMovement.moveX) {
      const totalWidth = this.environmentWidth;
      const intervalWidth = totalWidth / this.questionCount;
      const value = Math.round(intervalWidth * stepIndex);
      return value + intervalWidth * x;
    }
    return Math.round(this.environmentWidth * x);
  }

  getObstacleY(stepIndex = 0, frameIndex = 0): number {
    const y = this.getAnimationFrame(stepIndex, frameIndex).y;
    if (this.cameraMovement === CameraMovement.moveY) {
      const totalHeight = this.environmentHeight;
      const intervalHeight = totalHeight / this.questionCount;
      const value = Math.round(intervalHeight * stepIndex);
      return value + intervalHeight * y;
    }
    return Math.round(this.environmentHeight * y);
  }

  getObstacleAnchorX(stepIndex = 0): number {
    const step = this.getStep(stepIndex);
    if (Object.hasOwn(step, 'anchorX')) {
      return step.anchorX;
    }
    return this.animationConfig.stepAnchorX;
  }

  getObstacleAnchorY(stepIndex = 0): number {
    const step = this.getStep(stepIndex);
    if (Object.hasOwn(step, 'anchorY')) {
      return step.anchorY;
    }
    return this.animationConfig.stepAnchorY;
  }

  calcBackgroundX(index: number): number {
    const initBackgroundX = this.getInitBackgroundX(index);
    if (index > 0) {
      const totalWidth =
        this.initEnvironmentWidth * this.calcBackgroundZoom(index - 1);
      if (this.cameraMovement === CameraMovement.moveX) {
        const intervalWidth = totalWidth / this.questionCount;
        const value = Math.round(intervalWidth * index * -1);
        const maxValue = totalWidth - this.containerWidth;
        if (maxValue < 0) return 0;
        if (-value > maxValue) return -maxValue;
        return value;
      }
      if (
        this.cameraMovement === CameraMovement.movePath ||
        this.cameraMovement === CameraMovement.movePathX
      ) {
        const stepIndex = index - 1;
        const step = this.getStep(stepIndex);
        if (Object.hasOwn(step, 'pathX')) {
          const padding = this.animationConfig.padding * totalWidth;
          let delta = totalWidth * 0.5;
          if (this.movementAnchor === MovementAnchor.right)
            delta =
              this.getObstacleWidth(stepIndex, 0) *
                this.getObstacleAnchorX(stepIndex) +
              padding;
          if (this.movementAnchor === MovementAnchor.left)
            delta =
              -this.getObstacleWidth(stepIndex, 0) *
                (1 - this.getObstacleAnchorX(stepIndex)) -
              padding;
          const value = -step.pathX * totalWidth + delta + initBackgroundX;
          const maxValue = totalWidth - this.containerWidth;
          if (maxValue < 0) return initBackgroundX;
          if (-value > maxValue) return -maxValue;
          if (value > 0) return 0;
          return value;
        }
      }
    }
    return initBackgroundX;
  }

  calcBackgroundY(index: number): number {
    const initBackgroundY = this.getInitBackgroundY(index);
    if (index > 0) {
      const totalHeight =
        this.initEnvironmentHeight * this.calcBackgroundZoom(index - 1);
      if (this.cameraMovement === CameraMovement.moveY) {
        const intervalHeight = totalHeight / this.questionCount;
        const value = Math.round(intervalHeight * index * -1);
        const maxValue = totalHeight - this.containerHeight;
        if (maxValue < 0) return 0;
        if (-value > maxValue) return -maxValue;
        return value;
      }
      if (
        this.cameraMovement === CameraMovement.movePath ||
        this.cameraMovement === CameraMovement.movePathY
      ) {
        const stepIndex = index - 1;
        const step = this.getStep(stepIndex);
        if (Object.hasOwn(step, 'pathY')) {
          const padding = this.animationConfig.padding * totalHeight;
          let delta = totalHeight * 0.5;

          if (this.movementAnchor === MovementAnchor.top)
            delta =
              this.getObstacleHeight(stepIndex, 0) *
                this.getObstacleAnchorY(stepIndex) +
              padding;
          if (this.movementAnchor === MovementAnchor.bottom)
            delta =
              -this.getObstacleHeight(stepIndex, 0) *
                (1 - this.getObstacleAnchorY(stepIndex)) -
              padding;
          const value = -step.pathY * totalHeight + delta + initBackgroundY;
          const maxValue = totalHeight - this.containerHeight;
          if (maxValue < 0) return initBackgroundY;
          if (-value > maxValue) return -maxValue;
          if (value > 0) return 0;
          return value;
        }
      }
    }
    return initBackgroundY;
  }

  caleSpriteX(vehicleIndex: number): number {
    const totalWidth = this.environmentWidth;
    const intervalWidth = totalWidth / this.questionCount;
    const index = this.animationProps.foreground[vehicleIndex].index;
    const value = Math.round(intervalWidth * index * -1);
    const maxValue = this.environmentWidth - this.containerWidth;
    const delta = maxValue + value;
    const x = this.getSpriteX(this.vehicles[vehicleIndex]);
    if (delta < 0) return x - delta;
    return x;
  }

  //@Watch('animationProps.backgroundZoom', { immediate: true })
  @Watch('containerWidth', { immediate: true })
  @Watch('backgroundTexture', { immediate: true })
  onBackgroundZoomChanged(): void {
    this.animationProps.backgroundPositionX = this.calcBackgroundX(
      this.animationProps.index
    );
    this.animationProps.backgroundPositionY = this.calcBackgroundY(
      this.animationProps.index
    );
    this.animationProps.backgroundZoom = this.calcBackgroundZoom(
      this.animationProps.index - 1
    );
  }

  async mounted(): Promise<void> {
    this.animationProps.steps = this.animationConfig.steps.map((item) => {
      const props: StepProp = {
        tween: null,
        x: item.obstacleX,
        y: item.obstacleY,
        layers: null,
      };
      props.tween = new TWEEDLE.Tween(props).easing(
        TWEEDLE.Easing.Quadratic.InOut
      );
      if (item.layers) {
        props.layers = item.layers.map((layer) => {
          const layerProp: LayerProp = {
            tween: null,
            x: layer.obstacleX,
            y: layer.obstacleY,
            foreground: layer.foreground ?? false,
          };
          layerProp.tween = new TWEEDLE.Tween(layerProp).easing(
            TWEEDLE.Easing.Quadratic.InOut
          );
          return layerProp;
        });
      }
      return props;
    });
    this.animationProps.tween = new TWEEDLE.Tween(this.animationProps)
      .easing(TWEEDLE.Easing.Quadratic.InOut)
      .onStop(() => this.animationStop())
      .onComplete(() => this.animationend());
    window.addEventListener('resize', this.setupPixiSpace);
    until(() => this.$refs.canvasContainer).then(() => {
      this.setupPixiSpace();
      this.animationProps.foreground = this.vehicles.map((vehicle, index) => {
        const props: ForegroundProp = {
          index: 0,
          completed: 0,
          x: this.getSpriteX(vehicle),
          tween: null,
          loopIndex: 0,
        };
        props.tween = new TWEEDLE.Tween(props)
          .easing(TWEEDLE.Easing.Quadratic.InOut)
          .onUpdate(() => {
            this.animationProps.foregroundX =
              this.animationProps.foreground.map((item) => item.x);
          })
          .onStart((item) => (item.loopIndex = 0))
          .onStop(() => this.animationStop(index))
          .onComplete(() => this.animationend(index));
        return props;
      });
      this.animationProps.foregroundX = this.animationProps.foreground.map(
        (item) => item.x
      );
    });
    until(() => this.$refs.pixi).then(() => {
      const app = (this.$refs.pixi as any).app as PIXI.Application;
      app.ticker.add(() => TWEEDLE.Group.shared.update());
    });
    if (this.animationConfig.animation) {
      await pixiUtil
        .loadTexture(`/assets/animation/${this.animationConfig.animation}.json`)
        .then((img) => {
          this.animationSprite = img;
          for (let i = 0; i < this.vehicles.length; i++) {
            const vehicle = this.vehicles[i];
            const sprite = this.getCurrentVehicleSprite(vehicle);
            if (sprite) {
              vehicle.width = sprite.orig.width;
              vehicle.height = sprite.orig.height;
              vehicle.aspect = pixiUtil.getTextureAspect(sprite);
            }
            vehicle.scale = vehicle.height / this.vehicles[0].height;
          }
        });
    }
    if (this.stepCount > 0) {
      for (let i = 0; i < this.stepCount; i++) {
        const item = this.getStep(i);
        if (item.file) {
          await pixiUtil
            .loadTexture(`/assets/animation/${item.file}.json`)
            .then((sheet) => {
              item.sheet = sheet;
              if (i === this.animationProps.index) this.isLoaded = true;
            });
        } else {
          item.sheet = this.animationSprite;
          if (i === this.animationProps.index) this.isLoaded = true;
        }
        for (let j = 0; j < this.getFrameCount(i); j++) {
          const frame = this.getFrame(i, j);
          frame.sheet = this.animationSprite;
        }
      }
    } else {
      this.isLoaded = true;
    }
    if (this.animationConfig.background) {
      await pixiUtil
        .loadTexture(`/assets/animation/${this.animationConfig.background}`)
        .then((img) => {
          this.backgroundTexture = img;
        });
    }
  }

  unmounted(): void {
    window.removeEventListener('resize', this.setupPixiSpace);
    /*for (let i = 0; i < this.stepCount; i++) {
      const item = this.getFrame(i);
      pixiUtil.unloadTexture(`/assets/animation/${item.file}.json`);
    }*/
  }

  waitIndex = 0;
  @Watch('modelValue', { immediate: true })
  async onModelValueChanged(): Promise<void> {
    if (this.modelValue) {
      this.waitIndex++;
      this.$emit('update:modelValue', false);
      if (this.getStep(this.animationProps.index)?.wait) {
        const waitIndex = this.waitIndex;
        await until(
          () =>
            this.animationProps.foreground[this.vehicleIndex[0]].completed >=
            waitIndex - 1
        );
      }
      this.updateAnimationCompleted();
      const animate = (): void => {
        this.animationProps.index++;
        for (const index of this.vehicleIndex) {
          this.animationProps.foreground[index].index++;
        }
        if (this.stepCount === 0) this.obstacleCompleted();
      };
      if (this.animationTime === AnimationTime.after) {
        this.moveCamera(this.animationProps.index + 1, animate);
      } else animate();
    }
  }

  @Watch('animateFinalStep', { immediate: true })
  onAnimateFinalStep(): void {
    if (this.animateFinalStep) {
      if (
        this.animationConfig.finalBackgroundAnimation ===
          CameraMovement.moveX ||
        this.animationConfig.finalBackgroundAnimation === CameraMovement.moveY
      ) {
        if (this.animationProps.tween === null || !this.backgroundTexture) {
          this.$emit('finalCompleted');
          return;
        }
        const textureWidth = this.zoomedWidth;
        const textureHeight = this.zoomedHeight;
        const totalWidth =
          this.initEnvironmentWidth *
          this.calcBackgroundZoom(this.animationProps.index - 1);
        const totalHeight =
          this.initEnvironmentHeight *
          this.calcBackgroundZoom(this.animationProps.index - 1);
        const maxX =
          this.backgroundFill === BackgroundFill.height
            ? this.containerWidth - textureWidth
            : this.containerWidth - totalWidth;
        const maxY =
          this.backgroundFill === BackgroundFill.height
            ? this.containerHeight - totalHeight
            : this.containerHeight - textureHeight;
        const finalTime = 5000;
        const finalBackgroundX =
          this.animationConfig.finalBackgroundAnimation === CameraMovement.moveX
            ? maxX
            : this.animationProps.backgroundPositionX;
        const finalBackgroundY =
          this.animationConfig.finalBackgroundAnimation === CameraMovement.moveY
            ? maxY
            : this.animationProps.backgroundPositionY;
        this.animationProps.tween
          .from({
            backgroundPositionX: this.animationProps.backgroundPositionX,
            backgroundPositionY: this.animationProps.backgroundPositionY,
          })
          .to(
            {
              backgroundPositionX: finalBackgroundX,
              backgroundPositionY: finalBackgroundY,
            },
            finalTime
          )
          .easing(TWEEDLE.Easing.Quintic.In)
          .duration(this.animationDuration)
          .restart()
          .onComplete(() => {
            if (this.animationProps.foreground.length > 0) {
              for (let i = 0; i < this.animationProps.foreground.length; i++) {
                const tween = this.animationProps.foreground[i].tween;
                if (tween && this.vehicles[i].finalX) {
                  const finalX =
                    this.containerWidth + this.getSpriteWidth(this.vehicles[i]);
                  const distance = finalX - this.animationProps.foreground[i].x;
                  const timeFactor = -finalBackgroundX / distance;
                  const time = finalTime / timeFactor / 2;
                  tween
                    .from({
                      x: this.animationProps.foreground[i].x,
                    })
                    .to(
                      {
                        x: finalX,
                      },
                      time > 1000 ? time : 1000
                    )
                    .duration(this.animationDuration)
                    .restart()
                    .onComplete(() => this.$emit('finalCompleted'));
                }
              }
            } else {
              this.$emit('finalCompleted');
            }
          });
        /*for (let i = 0; i < this.animationProps.foreground.length; i++) {
          const tween = this.animationProps.foreground[i].tween;
          if (tween && this.vehicles[i].finalX) {
            let finalX = this.animationProps.foreground[i].x - finalBackgroundX;
            if (this.vehicles[i].finalX === 'leave') {
              finalX = textureWidth + this.getSpriteWidth(this.vehicles[i]);
            }
            tween
              .from({
                x: this.animationProps.foreground[i].x,
              })
              .to(
                {
                  x: finalX,
                },
                finalTime
              )
              .easing(TWEEDLE.Easing.Quintic.In)
              .duration(this.animationDuration)
              .restart();
          }
        }*/
      } else this.$emit('finalCompleted');
    }
  }

  async setupPixiSpace(): Promise<void> {
    const dom = this.$refs.canvasContainer as HTMLElement;
    if (
      dom &&
      (dom.offsetWidth !== this.containerWidth ||
        dom.clientHeight !== this.containerHeight)
    ) {
      this.containerWidth = dom.offsetWidth;
      this.containerHeight = dom.clientHeight;
      this.isContainerSet = true;
    }
  }

  endCheckIndex = 0;
  animationend(index = -1): void {
    if (index > -1) {
      this.animationProps.foreground[index].tween?.stop();
    } else if (this.animationProps.tween) {
      this.animationProps.tween.stop();
    }
    if (this.animationTime !== AnimationTime.after) {
      this.updateAnimationCompleted();
    }
  }

  animationStop(index = -1): void {
    if (index > -1) {
      this.animationProps.foreground[index].completed++;
    }
  }

  updateAnimationCompleted(): void {
    if (this.endCheckIndex < this.animationProps.index) {
      this.endCheckIndex = this.animationProps.index;
      delay(1000).then(() => {
        this.$emit('animationCompleted');
      });
    }
  }

  getPlayParameter(index = -1): {
    playTime: number;
    remaining: number;
    easing: TWEEDLE.EasingFunction;
  } {
    const tween =
      index > -1
        ? this.animationProps.foreground[index].tween
        : this.animationProps.tween;
    const elapsedTime = (tween as any)._elapsedTime;
    const duration = (tween as any)._duration;
    let playTime = this.defaultDuration;
    let easing = TWEEDLE.Easing.Quadratic.InOut;
    let remaining = 0;
    if (elapsedTime > 0 && elapsedTime < duration) {
      remaining = duration - elapsedTime;
      playTime = this.defaultDuration + remaining;
      if (remaining > 500) easing = TWEEDLE.Easing.Quadratic.Out;
    }
    return {
      playTime: playTime,
      remaining: remaining,
      easing: easing,
    };
  }

  moveCamera(index: number, completedCall: () => void): void {
    if (this.animationProps.tween === null) return;
    const backgroundXTo = this.calcBackgroundX(index);
    const backgroundYTo = this.calcBackgroundY(index);
    const backgroundZoomTo = this.calcBackgroundZoom(index - 1);
    if (
      this.animationProps.backgroundPositionX !== backgroundXTo ||
      this.animationProps.backgroundPositionY !== backgroundYTo ||
      this.animationProps.backgroundZoom !== backgroundZoomTo
    ) {
      const frame = this.getStep(index - 1);
      if (frame) {
        const step = this.animationProps.steps[index - 1];
        if (step.tween && (frame.obstacleX2 || frame.obstacleY2)) {
          step.tween
            .from({
              x: step.x,
              y: step.y,
            })
            .to(
              {
                x: frame.obstacleX2 ?? frame.obstacleX,
                y: frame.obstacleY2 ?? frame.obstacleY,
              },
              this.defaultDuration / 10
            )
            .duration(this.animationDuration)
            .start()
            .onComplete(() => completedCall());
        }
      }
      const param = this.getPlayParameter();
      if (param.remaining === 0) {
        this.animationProps.tween
          .stop()
          .from({
            backgroundPositionX: this.animationProps.backgroundPositionX,
            backgroundPositionY: this.animationProps.backgroundPositionY,
            backgroundZoom: this.animationProps.backgroundZoom,
          })
          .to(
            {
              backgroundPositionX: backgroundXTo,
              backgroundPositionY: backgroundYTo,
              backgroundZoom: backgroundZoomTo,
            },
            param.playTime
          )
          .easing(param.easing)
          .duration(this.animationDuration)
          .start()
          .onStop(() => completedCall());
      } else {
        this.animationProps.tween
          .from({
            backgroundPositionX: this.animationProps.backgroundPositionX,
            backgroundPositionY: this.animationProps.backgroundPositionY,
            backgroundZoom: this.animationProps.backgroundZoom,
          })
          .to(
            {
              backgroundPositionX: backgroundXTo,
              backgroundPositionY: backgroundYTo,
              backgroundZoom: backgroundZoomTo,
            },
            param.playTime
          )
          .easing(param.easing)
          .duration(this.animationDuration)
          .restart()
          .onStop(() => completedCall());
      }
    } else if (this.animationProps.foreground.length === 0) {
      completedCall();
    }
  }

  completedIndex = -1;
  obstacleCompleted(): void {
    if (this.animationProps.tween === null) return;
    this.completedIndex = this.animationProps.index;
    for (const index of this.vehicleIndex) {
      const vehicleProps = this.animationProps.foreground[index];
      if (vehicleProps.tween) {
        const param = this.getPlayParameter(index);
        const vehicleXTo = this.caleSpriteX(index);
        if (param.remaining === 0) {
          vehicleProps.tween
            .stop()
            .from({ x: vehicleProps.x })
            .to({ x: vehicleXTo }, param.playTime)
            .easing(param.easing)
            .duration(this.animationDuration)
            .start();
        } else {
          vehicleProps.tween
            .from({ x: vehicleProps.x })
            .to({ x: vehicleXTo }, param.playTime)
            .easing(param.easing)
            .duration(this.animationDuration)
            .restart();
        }
      }
    }

    if (this.animationTime !== AnimationTime.after)
      this.moveCamera(this.animationProps.index, this.updateAnimationCompleted);
    else this.updateAnimationCompleted();
  }

  vehicleLoop(vehicleIndex: number): void {
    this.animationProps.foreground[vehicleIndex].loopIndex++;
  }

  onDrawBounds(graphic: PIXI.Graphics): void {
    graphic.lineStyle(2, 0xff00ff, 1);
    graphic.drawRect(0, 0, 1, this.containerHeight);
  }

  onDrawBounds2(graphic: PIXI.Graphics): void {
    graphic.lineStyle(2, 0x00ffff, 1);
    graphic.drawRect(0, 0, this.containerWidth, 1);
  }
}
</script>

<style lang="scss" scoped>
.canvasContainer {
  width: 100%;
  height: 100%;
  background-color: var(--color-sky);
  background-size: var(--sizeX) var(--sizeY);
  background-position-x: var(--x);
  background-position-y: var(--y);
  background-repeat: no-repeat;
}

div,
img {
  width: 100%;
  height: 100%;
  background-color: var(--color-main-light);
}

img {
  object-fit: contain;
}

.backgroundOverlay {
  position: absolute;
  background-size: var(--sizeX) var(--sizeY);
  background-position-x: var(--x);
  mask-image: linear-gradient(to right, black 0%, black 100%);
  mask-repeat: no-repeat;
  mask-size: var(--percent) 100%;
  transition: mask-size 2s ease;
}
</style>
