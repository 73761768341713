<template>
  <el-container
    v-if="quiz && showIntro"
    class="intro"
    :style="{ '--color': quiz.settings.color }"
    :lang="$i18n.locale"
  >
    <el-main>
      <h1>{{ quiz.title }}</h1>
      <div v-if="quiz.description">{{ quiz.description }}</div>
    </el-main>
    <el-footer>
      <font-awesome-icon :icon="['fac', 'logo']" @click="showIntro = false" />
    </el-footer>
  </el-container>
  <el-container
    v-else-if="quiz"
    :style="{
      '--color-main': quiz.settings.color,
      '--color-main-light': 'hsl(from var(--color-main) h 30 70)',
      '--color-main-dark': 'hsl(from var(--color-main) h s 30)',
    }"
    :lang="$i18n.locale"
  >
    <MultipleChoice
      v-if="
        !showIntro &&
        !showResult &&
        quiz.settings.answer_type === AnswerType.CHOICE
      "
      :quiz="quiz"
      :question="questions[index]"
      :answers="answers"
      :choices="choices"
      :is-correct="isCorrect"
      :show-hint="showHint"
      :show-info="showInfo"
      :question-no="index"
      :stateList="stateList"
      :can-confirm="canConfirm"
      v-model:show-animation="showAnimation"
      :animate-final-step="showFinal"
      @confirm="confirm"
      @animationCompleted="animationCompleted"
      @finalCompleted="finalCompleted"
    />
    <DragAndDrap
      v-if="
        !showIntro &&
        !showResult &&
        quiz.settings.answer_type === AnswerType.DRAG_AND_DROP
      "
      :quiz="quiz"
      :question="questions[index]"
      :answers="answers"
      :choices="choices"
      :is-correct="isCorrect"
      :show-hint="showHint"
      :show-info="showInfo"
      :question-no="index"
      :stateList="stateList"
      :can-confirm="canConfirm"
      v-model:show-animation="showAnimation"
      :animate-final-step="showFinal"
      @confirm="confirm"
      @animationCompleted="animationCompleted"
      @finalCompleted="finalCompleted"
    />
    <quiz-layout v-if="showResult">
      <el-main class="result">
        <div>
          <el-rate :max="3" :disabled="true" v-model="stars" />
        </div>
        <div>
          {{ correctCount }} / {{ questions.length }}
          {{ $t('views.quiz.correctCount') }}
        </div>
        <div>{{ hintCount }} {{ $t('views.quiz.hintCount') }}</div>
        <div>{{ $t(`views.quiz.starResult.${stars}`) }}</div>
        <button @click="$router.back()" class="confirm">
          <font-awesome-icon icon="rotate-left" />
          {{ $t('views.quiz.confirm') }}
        </button>
        <button @click="goToInfo" class="confirm">
          <font-awesome-icon icon="circle-info" />
          {{ $t('views.quiz.link') }}
        </button>
        <table class="highscore">
          <tr>
            <th></th>
            <th></th>
            <th>
              {{ $t('views.quiz.correctCount') }}
            </th>
            <th>
              {{ $t('views.quiz.hintCount') }}
            </th>
          </tr>
          <tr
            v-for="(item, index) in highscore"
            :key="index"
            :class="{ highlight: item.player_key === playerKey }"
          >
            <td>{{ index + 1 }}</td>
            <td><el-rate disabled :max="3" v-model="item.stars" /></td>
            <td>{{ item.correct }} / {{ item.count }}</td>
            <td>{{ item.hints }}</td>
          </tr>
        </table>
      </el-main>
    </quiz-layout>
  </el-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as quizService from '@/services/quiz-service';
import * as questionService from '@/services/question-service';
import { Quiz } from '@/types/api/Quiz';
import { Question } from '@/types/api/Question';
import MultipleChoice from '@/components/MultipleChoice.vue';
import DragAndDrap from '@/components/DragAndDrap.vue';
import { AnswerType } from '@/types/enum/AnswerType';
import { Answer } from '@/types/api/Answer';
import { Choice } from '@/types/api/Choice';
import { v4 as uuidv4 } from 'uuid';
import * as answerService from '@/services/answer-service';
import * as choiceService from '@/services/choice-service';
import * as questionResultService from '@/services/question-result-service';
import * as resultService from '@/services/result-service';
import { QuestionState } from '@/types/quiz/QuestionState';
import { QuizResult } from '@/types/api/QuizResult';
import { QuestionResult } from '@/types/api/QuestionResult';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as authService from '@/services/auth-service';
import QuizLayout from '@/components/QuizLayout.vue';

@Options({
  components: {
    QuizLayout,
    FontAwesomeIcon,
    DragAndDrap,
    MultipleChoice,
  },
  emits: [],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class QuizView extends Vue {
  @Prop() readonly id!: number;
  @Prop({ default: 'de' }) readonly locale!: string;
  quiz: Quiz | null = null;
  questions: Question[] = [];
  AnswerType = AnswerType;

  playerKey = '';
  index = 0;
  answers: Answer[] = [];
  choices: Choice[] = [];
  stateList: QuestionState[] = [];
  highscore: QuizResult[] = [];
  result: QuestionResult | null = null;
  showIntro = true;

  showHint = false;
  showAnimation = false;
  showInfo = false;
  confirmCount = 0;
  isCorrect = false;
  readonly intervalTimeWithoutInfo = 2000;
  readonly intervalTimeWithInfo = 5000;

  get canConfirm(): boolean {
    let choiceCount = 0;
    for (const choice of this.choices) {
      if (choice.result) choiceCount++;
    }
    return choiceCount > 0;
  }

  get correctCount(): number {
    let count = 0;
    for (const item of this.stateList) {
      if (item.isCorrect) count++;
    }
    return count;
  }

  get hintCount(): number {
    let count = 0;
    for (const item of this.stateList) {
      if (item.useHint) count++;
    }
    return count;
  }

  get stars(): number {
    if (this.correctCount === this.questions.length && this.hintCount === 0)
      return 3;
    if (this.correctCount === this.questions.length) return 2;
    if (this.correctCount >= (this.questions.length / 3) * 2) return 1;
    return 0;
  }

  set stars(value: number) {
    console.log(value);
  }

  get showFinal(): boolean {
    return (
      this.index > 0 &&
      this.index >= this.questions.length &&
      this.animationIndexQueue.length === 0
    );
  }

  get showResult(): boolean {
    return this.finalDone && this.showFinal;
  }

  async mounted(): Promise<void> {
    authService.setLocale(this.locale, this.$i18n as any);
    this.playerKey = uuidv4();
    this.quiz = await quizService.getQuiz(this.id, this.locale);
    setTimeout(() => (this.showIntro = false), 2000);
    const questionCount = this.quiz.settings.count;
    questionService.getQuestionList(this.id, this.locale).then((list) => {
      const temp = [...list];
      while (this.questions.length < questionCount && temp.length > 0) {
        const index = Math.floor(Math.random() * temp.length);
        this.questions.push(temp[index]);
        temp.splice(index, 1);
      }
      this.stateList = this.questions.map(() => {
        return {
          isAnswered: false,
          isCorrect: false,
          useHint: false,
        };
      });
      this.onIdChanged();
    });
  }

  @Watch('index', { immediate: true })
  onIdChanged(): void {
    if (this.questions.length > 0 && this.index >= this.questions.length) {
      resultService.postResult(
        this.playerKey,
        this.id,
        this.correctCount,
        this.questions.length,
        this.hintCount,
        this.stars
      );
      resultService.getResultList(this.id).then((list) => {
        this.highscore = list.sort((a, b) => {
          if (a.correct === b.correct) return a.hints - b.hints;
          return b.correct - a.correct;
        });
      });
    } else if (
      this.questions.length > 0 &&
      this.index < this.questions.length
    ) {
      this.result = null;
      this.confirmCount = 0;
      this.showHint = false;
      this.showInfo = false;
      answerService
        .getAnswerList(this.questions[this.index].id, this.locale)
        .then((list) => {
          this.answers = list;
          this.choices = list.map((item) => {
            return {
              id: null,
              player_key: this.playerKey,
              answer: item.id,
              result: false,
              hint: false,
              timestamp: new Date(),
            };
          });
        });
    }
  }

  @Watch('showInfo', { immediate: true })
  onShowInfoChanged(): void {
    if (this.showInfo) {
      setTimeout(
        () => {
          this.index++;
        },
        this.isCorrect && !this.questions[this.index].explanation
          ? this.intervalTimeWithoutInfo
          : this.intervalTimeWithInfo
      );
    }
  }

  confirm(): void {
    let isCorrect = true;
    for (let i = 0; i < this.choices.length; i++) {
      const choice = this.choices[i];
      const answer = this.answers[i];
      if (this.confirmCount === 0) {
        choiceService.postChoice(choice).then((item) => {
          choice.id = item.id;
          choice.timestamp = item.timestamp;
        });
      } else {
        choice.hint = true;
        choiceService.putChoice(choice);
      }
      if (choice.result !== answer.correct) isCorrect = false;
    }
    this.isCorrect = isCorrect;
    if (isCorrect) {
      this.showAnimation = true;
      this.showInfo = true;
    } else {
      this.showHint = true;
      if (this.confirmCount > 0) {
        this.showInfo = true;
      } else {
        this.confirmCount++;
      }
    }
    if (!this.result) {
      this.result = {
        id: null,
        player_key: this.playerKey,
        question: this.questions[this.index].id,
        correct: isCorrect,
        hint: this.showHint,
        timestamp: new Date(),
      };
      questionResultService.postQuestionResult(this.result).then((item) => {
        this.result = item;
      });
    } else {
      this.result.correct = isCorrect;
      this.result.hint = this.showHint;
      questionResultService.putQuestionResult(this.result);
    }
    this.stateList[this.index].isAnswered = this.showInfo;
    this.stateList[this.index].isCorrect = isCorrect;
    this.stateList[this.index].useHint = this.showHint;
  }

  goToInfo(): void {
    if (this.quiz) {
      location.href = this.quiz.info_link;
    }
  }

  @Watch('showAnimation', { immediate: true })
  onShowAnimationChanged(): void {
    if (this.showAnimation) {
      this.animationIndexQueue.push(this.index);
    }
  }

  animationIndexQueue: number[] = [];
  animationCompleted(): void {
    this.animationIndexQueue.shift();
  }

  finalDone = false;
  finalCompleted(): void {
    this.finalDone = true;
  }
}
</script>

<style lang="scss" scoped>
button {
  display: block;
  font-size: var(--font-size-default);
  margin-bottom: 0.5rem;
  width: 100%;
  background-color: var(--color-main);
  color: var(--color-background);
  text-align: center;
}

.result {
  padding: 1rem;
  text-align: center;
  padding-top: 3rem;

  > div {
    padding-bottom: 1rem;
  }

  .el-rate::v-deep(.el-icon) {
    height: 2em;
    width: 2em;
  }

  .el-rate::v-deep(.el-icon svg) {
    height: 2em;
    width: 2em;
  }
}

table {
  text-align: right;
  width: 100%;
  margin-top: 2rem;
  border-collapse: collapse;

  th,
  td {
    padding: 0.1rem 0.5rem;
  }
}

.highlight {
  font-weight: var(--font-weight-semibold);
  background-color: var(--color-main);
  color: var(--color-background);
}

.intro {
  background-color: var(--color);
  color: white;
  height: var(--app-height);
  padding: 7rem 3rem 1rem 3rem;
  word-break: break-word;

  h1 {
    margin: 0;
    font-size: var(--font-size-h1);
    color: white;
  }

  .el-footer {
    background-image: url('@/assets/img/water.png');
    background-repeat: repeat-x;
    background-size: 5rem;
    background-position-y: bottom;
    background-position-x: 2.5rem;
    font-size: 3rem;
    height: unset;
    padding: 0 3rem;
    margin: 0 -3rem -1rem;
    line-height: 5.8rem;
  }

  .el-main {
    padding: 0;
  }
}

.el-header,
.el-footer {
  height: unset;
}

.quiz-content {
  height: 100dvh;
  background-color: #ffffff;
}
</style>
